import { CameraIcon } from '@heroicons/react/24/outline'
import React from "react";

export default function Navbar() {
  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <div className="bg-image"></div>
        <div className="title-font font-medium text-white mb-4 md:mb-0">
          <a href="About" className="ml-3 text-xl hover:text-white">
          <span className="font-link">J.D McCurdy</span>
          </a>
        </div>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center text-base justify-center">
          <a href="Home" className="mr-5 hover:text-white">
            Home
          </a>
          <a href="Gallery" className="mr-5 hover:text-white">
            Gallery
          </a>
          <a href="Shop" className="mr-5 hover:text-white">
            Shop
          </a>
        </nav>
        <a
          href="/Contact"
          className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
        >
          <span className="mr-2">Contact</span>
          <CameraIcon className="h-6 w-6" />
        </a>
      </div>
    </header>
  );
}
