import { CameraIcon } from '@heroicons/react/24/outline';
//import { CameraIcon } from '@heroicons/react/24/outline';
import React from "react";

export default function Footer() {
  return (
    <footer className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-1/4 bg-blue-200 bg-opacity-50 rounded-3xl">
      <div className="container mx-auto flex flex-wrap p-5 items-center justify-center">
        <div className="title-font font-medium text-white mb-4 md:mb-0">
        </div>
        <a
          href="/Contact"
          className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
        >
          <span className="mr-2">Contact</span>
          <CameraIcon className="h-6 w-6" />
        </a>
      </div>
    </footer>
  );
}
